import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { ILoginRequest } from '../../Models/Requests/LoginRequest';
import { IAuthResponse, IUserAuthResponse } from '../../Models/Responses/UserAuthResponse';
import { IManageUserResponse } from '../../Models/Responses/ManageUsersResponse';
import { userInfoSlice } from './UserInfoSlice';
import { UserPermission } from '../../Models/Responses/UserPermissions';
import { requestPasswordReset } from './UserThunks';
import { NetError } from '../../Api/NetError';
import { uiSlice } from '../UI/UISlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { globalSession } from '../../../tracing/session';
import { jwtDecode } from 'jwt-decode';

const api6 = new Api('/api/6', undefined, undefined);

export const loginUser = createTracedAsyncThunk<IUserAuthResponse, ILoginRequest, { dispatch: AppDispatch, state: RootState }>(
	'auth/login',
	async (context, request: ILoginRequest, thunkAPI) => 
	{
		try
		{
			thunkAPI.dispatch(uiSlice.actions.resetPostLoginProgress({ loginType: 'password' }));

			const response = await api6.postUnauthorizedAsync<IUserAuthResponse>('authentication', request);
			if (response.ErrorCode === null && response.Success)
			{
				thunkAPI.dispatch(authSlice.actions.setAuthToken(response.Data));
				thunkAPI.dispatch(userInfoSlice.actions.setUserInfo(response.Data));
				
				globalSession.initializeSession(
					response.Data.AccessToken, 
					response.Data.UserId, 
					response.Data.FoundationId, 
					response.Data.UserEmail, 
					response.Data.UserName);
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const tokenExchange = createTracedAsyncThunk<IAuthResponse, string, { dispatch: AppDispatch, state: RootState }>(
	'auth/exchange',
	async (context, token: string, thunkAPI) => 
	{
		try
		{
			thunkAPI.dispatch(uiSlice.actions.resetPostLoginProgress({ loginType: 'exchange_token' }));

			const response = await api6.getUnauthorizedAsync<IAuthResponse>(`sso/${ encodeURIComponent(token) }`);

			if (response.ErrorCode === null && response.Success)
			{
				thunkAPI.dispatch(authSlice.actions.setAuthToken(response.Data.User));
				thunkAPI.dispatch(userInfoSlice.actions.setUserInfo(response.Data.User));
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

interface ISsoExchangeResponse 
{
	AccessToken: string;
	Fields: Omit<IUserAuthResponse, 'AccessToken'>;
	State?: Record<string, string>;
}

/**
 * Use our new v5 endpoint to trade an exchange token for an auth state.
 */
export const ssoExchange = createTracedAsyncThunk<IAuthResponse, { token:string }, { dispatch: AppDispatch, state: RootState }>(
	'sso/exchange',
	async (context, {token}, thunkAPI) => 
	{
		try
		{
			thunkAPI.dispatch(uiSlice.actions.resetPostLoginProgress({ loginType: 'sso'}));

			const response = await api6.getUnauthorizedAsync<ISsoExchangeResponse>(`sso/${ encodeURIComponent(token) }?system=fields`);

			if (response.ErrorCode === null && response.Success)
			{
				if(!response.Data.Fields)
				{
					return thunkAPI.rejectWithValue({code: 'NOT_A_USER', message: 'This account does not exist yet in GHX Fields.  Please contact your support specialist to get access.'});
				}

				const authResponse: IAuthResponse = {
					User: {
						...response.Data.Fields,
						AccessToken: response.Data.AccessToken,
					},
				};

				thunkAPI.dispatch(userInfoSlice.actions.setUserInfo({
					...authResponse.User,
					ssoLogin: true,
				}));

				const intialUserFoundationId = response.Data.State?.seller;
				const initialGrowerFoundationId = response.Data.State?.grower;
				const initialOrderId = response.Data.State?.order;

				thunkAPI.dispatch(uiSlice.actions.setInitialUserFoundationId(intialUserFoundationId));
				thunkAPI.dispatch(uiSlice.actions.setInitialGrowerFoundationId(initialGrowerFoundationId));
				thunkAPI.dispatch(uiSlice.actions.setInitialSalesforceOrderId(initialOrderId));

				return authResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({code: 'API_ERROR', message: response.ErrorMessage});
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			if(e instanceof NetError)
			{
				if(e.code === 404)
				{
					return thunkAPI.rejectWithValue({code: 'INVALID_TOKEN', message: 'The authentication token used is not valid; please log in again. If the problem persists, contact your support specialist.'});
				}
			}
			return thunkAPI.rejectWithValue({code: 'GENERAL_ERROR', message: e.message});
		}
	}
);

export const getManageableUsers = createTracedAsyncThunk<IManageUserResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'auth/manageableUsers/get',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<IManageUserResponse[]>('authorization/manageable', request);

			if (response.ErrorCode === null && response.Success)
			{
				const state = thunkAPI.getState().auth;
				const payload = response.Data;

				// If the currently managed user is not in this list, pick a different one!
				if(payload.length > 0)
				{
					if(!state.selectedUser || !payload.find(mu => mu.UserId === state.selectedUser.UserId) )
					{
						const list = payload.sort((a,b) => a.UserName.localeCompare(b.UserName, undefined, {'sensitivity': 'base'}));
						thunkAPI.dispatch(setManagedUser(list[0]));
						thunkAPI.dispatch(getManageableUsers());
					}
				}

				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export const validateToken = createTracedAsyncThunk<boolean, string, { state: RootState }>(
	'auth/validateToken',
	async (context, token: string, thunkAPI) => 
	{
		try 
		{
			const response = await api6.postUnauthorizedAsync<boolean>('authentication/validate', token);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IAuthState
{
	errorMessage: string;
	isAuthenticated: boolean;
	isError: boolean;
	isLoading: boolean;
	isLoginError: boolean;
	isSuccess: boolean;
	loginErrorMessage: string;
	manageableUsers: IManageUserResponse[];
	manageableUsersError: string | undefined;
	selectedUser: IManageUserResponse;
	successMessage: string;
	userAuthToken: string;
}

export const initialState: IAuthState = {
	errorMessage: undefined,
	isAuthenticated: false,
	isError: false,
	isLoading: false,
	isLoginError: false,
	isSuccess: undefined,
	loginErrorMessage: undefined,
	manageableUsers: undefined,
	manageableUsersError: undefined,
	selectedUser: undefined,
	successMessage: undefined,
	userAuthToken: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state.isAuthenticated = false;
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;
			state.userAuthToken = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.isLoginError = false;
			state.errorMessage = undefined;
			state.loginErrorMessage = undefined;
		},
		clearManageableUsersError: (state) => 
		{
			state.manageableUsersError = undefined;
		},
		setAuthToken: (state, { payload }: PayloadAction<IUserAuthResponse>) =>
		{
			state.userAuthToken = payload.AccessToken;
		},
		setManagedUser: (state, { payload }: PayloadAction<IManageUserResponse | undefined>) =>
		{
			state.selectedUser = payload;
		},
		clearSuccess: (state) =>
		{
			state.isSuccess = false;
			state.successMessage = undefined;

			return state;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(loginUser.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
			state.isError = false;
			state.isAuthenticated = false;
			state.errorMessage = undefined;
			state.userAuthToken = undefined;
			state.selectedUser = undefined;

		});
		builder.addCase(loginUser.fulfilled, (state) =>
		{
			state.isLoading = false;
			state.isAuthenticated = true;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
		});
		builder.addCase(loginUser.rejected, (state, action) =>
		{
			handleAuthenticationRejection(state, action);
		});
		

		builder.addCase(tokenExchange.pending, (state) =>
		{
			state.isLoading = true;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
			state.isError = false;
			state.isAuthenticated = false;
			state.errorMessage = undefined;
			state.userAuthToken = undefined;
			state.selectedUser = undefined;
		});
		builder.addCase(tokenExchange.fulfilled, (state) =>
		{
			state.isLoading = false;
			state.isAuthenticated = true;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
		});
		builder.addCase(tokenExchange.rejected, (state, action) =>
		{
			handleAuthenticationRejection(state, action);
		});

		builder.addCase(ssoExchange.pending, (state) =>
		{
			state.isLoading = true;
			state.selectedUser = undefined;
			state.isAuthenticated = false;
			state.userAuthToken = undefined;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
			state.isError = false;
			state.errorMessage = undefined;
		});

		builder.addCase(ssoExchange.fulfilled, (state, action) =>
		{
			state.isLoading = false;
			state.isAuthenticated = true;
			state.isLoginError = false;
			state.loginErrorMessage = undefined;
			state.userAuthToken = action.payload.User.AccessToken;		
		});

		builder.addCase(ssoExchange.rejected, (state, action) => 
		{
			handleAuthenticationRejection(state, action);
		});

		builder.addCase(validateToken.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(validateToken.fulfilled, (state, { payload }: PayloadAction<boolean>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
			state.isAuthenticated = payload;
		});
		builder.addCase(validateToken.rejected, (state, action) =>
		{
			state.userAuthToken = undefined;
			state.isAuthenticated = false;
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				state.errorMessage = 'There was an error authenticating.';
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});

		// manage users listing
		builder.addCase(getManageableUsers.fulfilled, (state, { payload }: PayloadAction<IManageUserResponse[]>) => 
		{
			state.manageableUsers = payload;
			state.manageableUsersError = undefined;
		});
		builder.addCase(getManageableUsers.rejected, (state, action) =>
		{
			if (action.payload)
			{
				const payloadMessage = action.payload as string;
				if (payloadMessage.indexOf('network') > -1)
				{
					state.manageableUsersError = 'There was a problem contacting the server to get the list of manageable users. Please refresh to try again.';
				}
				else
				{
					state.manageableUsersError = 'There was a problem downloading the list of manageable users. Please refresh to try again.';
				}
			}
			else
			{
				state.manageableUsersError = action.error.message;
			}
		});

		/**
		 * User Password Recovery Request
		 */
		builder.addCase(requestPasswordReset.pending, (state) =>
		{
			state.isLoading = true;
		});
		builder.addCase(requestPasswordReset.fulfilled, (state) =>
		{
			state.isLoading = false;

			// If a success message is passed in, display it
			state.successMessage = 'An email has been sent containing instructions on how to reset your password.';

			state.isSuccess = true;
		});
		builder.addCase(requestPasswordReset.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to request a password recovery email. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem requesting a password recovery email. Please try again.';
			}
		});
	}
});

/**
 * A selector to get what user context we need to use
 */
export const getCurrentActingUser = (state: RootState, getManagingUser: boolean = false) =>
{
	// Return the logged in user (managing user) regardless of a selected user
	if (getManagingUser)
	{
		return state.userInfo;
	} 
	
	// If there is a selected user, return the selected user's data
	if (state.auth.selectedUser)
	{
		return state.auth.selectedUser;
	}

	// Otherwise return the authenticated user's data
	return state.userInfo;
};

export const canManageAccounts = (state: RootState) =>
{
	return state.auth.manageableUsers?.length > 1;
};

export const isManagingAccount = (state: RootState) =>
{
	return state.userInfo.UserId !== getCurrentActingUser(state).UserId;
};

export const hasEffectivePermission = (state: RootState, permission: UserPermission, getManagingUser: boolean = false) =>
{
	return getCurrentActingUser(state, getManagingUser).Permissions?.indexOf(permission) >= 0;
};

export const { clearState, clearError, setManagedUser, clearSuccess } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

/**
 * A function for handling the common cases where authentication didn't work. 
 */
function handleAuthenticationRejection(state: IAuthState, action) 
{
	state.userAuthToken = undefined;
	state.isAuthenticated = false;
	state.isLoading = false;
	state.isLoginError = true;
	if (typeof action.payload === 'string') 
	{
		const payloadMessage = action.payload as string;
		if (payloadMessage.indexOf('network') > -1) 
		{
			state.loginErrorMessage = 'There was a problem contacting the server. Please try again.';
		}
		else if (payloadMessage.indexOf('credentials') > -1) 
		{
			state.loginErrorMessage = 'The provided credentials are incorrect. Please try again.';
		}
		else if (payloadMessage.indexOf(action.meta.arg.username) > -1) 
		{
			state.loginErrorMessage = payloadMessage;
		}
		else if (payloadMessage.indexOf('locked') > -1)
		{
			state.loginErrorMessage = payloadMessage;
		}
		else 
		{
			state.loginErrorMessage = 'There was a problem contacting the server. Please try again.';
		}
	}
	else if(typeof action.payload === 'object' && (action.payload as any).code)
	{
		state.loginErrorMessage = (action.payload as any).message;
	}
	else 
	{
		state.loginErrorMessage = 'There was a problem contacting the server. Please try again.';
	}
}


/**
 * Validates a JWT token and checks if it's close to expiration
 * 
 * @param jwt - The JSON Web Token to validate
 * @returns boolean - True if the token is valid and not near expiration, false otherwise
 * 
 * A token is considered invalid if:
 * 1. It's undefined/null/empty
 * 2. It can't be decoded
 * 3. It's expired or will expire within the next hour
 * 
 * The 1-hour buffer ensures users aren't logged out mid-session when their token
 * is about to expire.
 */
export function isJwtValidAndUnexpired(jwt: string | undefined): boolean
{
	if(!jwt)
	{
		return false;
	}

	try 
	{
		// Decode JWT and extract expiration time
		const decoded: any = jwtDecode(jwt);
		
		// Convert exp from Unix timestamp (seconds) to milliseconds
		const expirationMs = decoded?.exp * 1000;
		
		// Token is invalid if:
		// - No expiration time exists
		// - Current time + 1 hour is greater than expiration time
		const isExpired = !expirationMs || expirationMs < (Date.now() + 60 * 60 * 1000);

		return !isExpired;
	}
	catch (e) 
	{
		// If token can't be decoded, consider it invalid
		console.error('Error decoding JWT:', e);
		return false;
	}
}
